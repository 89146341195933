const logotext = "Computer Scientist";
const meta = {
  title: "Fernando Terceros",
  description: "I’m Fernando Terceros, a Computer Scientist and Full Stack Developer. I love solving problems and am currently working in Brazil.",
};

const introdata = {
  title: "I’m Fernando Terceros",
  animated: {
    first: "I love coding APIs with Java Spring and NestJS",
    second: "I create cool websites with React and Angular.",
    third: "I am currently studying to develop mobile apps with Flutter.",
  },
  description: "I’m Fernando Terceros, a Computer Scientist and Full Stack Developer. I love solving problems and am currently working in Brazil.",
  your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
  title: "Abit about my self",
  aboutme:
    "I’m Fernando Terceros, a Computer Scientist and Full Stack Developer. I love solving problems and am currently working in Brazil. I am a native Spanish speaker, fluent in Portuguese, and intermediate in English.",
};
const worktimeline = [
  {
    jobtitle: "Full stack developer, React and Angular",
    where: "hacker space",
    date: "2023-2024",
  },
  {
    jobtitle: "Backend developer, Node and Java Spring Boot",
    where: "hacker space",
    date: "2020-2023",
  },
  {
    jobtitle: "Senior Electronics and Designer, IoT solutions",
    where: "hacker space",
    date: "2004-2020",
  },
];

const skills = [
  {
    name: "NodeJs",
    value: 95,
  },
  {
    name: "Java Spring Boot",
    value: 95,
  },
  {
    name: "Python",
    value: 80,
  },
  {
    name: "React",
    value: 70,
  },
  {
    name: "NestJs",
    value: 40,
  },
  {
    name: "Angular",
    value: 30,
  },
];

const services = [
  {
    title: "Design APIs with Java Spring and NestJS",
    description: "Java Spring API Design, NestJS API Design, and API Documentation and Testing with Swagger, Jest, JUnit5.",
  },
  {
    title: "Web Apps",
    description:
      "Web App Development with React, Angular. Responsive Design, Mobile First Development, using Bootstrap, Material-UI, Test Driven Development with Jest.",
  },
  {
    title: "Electronics Designer, IoT solutions",
    description: "Custom Electronics Design, Firmware Development, and IoT Solutions for Your Business.",
  },
];

const dataportfolio = [
  {
    img: "https://picsum.photos/400/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/800/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/600/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/300/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/700/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },

  {
    img: "https://picsum.photos/400/600/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/300/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/550/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/700/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
];

const contactConfig = {
  YOUR_EMAIL: "name@domain.com",
  YOUR_FONE: "(19) 12345-6789",
  description: "I am available for freelance work. Connect with me via email or phone.",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id",
};

const socialprofils = {
  github: "https://github.com/jciterceros/",
  facebook: "https://facebook.com",
  linkedin: "https://www.linkedin.com/in/fernando-flores-terceros-83486625/",
  twitter: "https://twitter.com",
};
export { meta, dataabout, dataportfolio, worktimeline, skills, services, introdata, contactConfig, socialprofils, logotext };
